import React from 'react'
import PersonalInfo from '../PersonalInfo/PersonalInfo'



const Home = () => {
  return (
    <div>
      <PersonalInfo/>
    </div>
    
  )
}

export default Home
